import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./about-page.css";

const AboutPage = () => {
  const contentStyle = {
    padding: "2rem",
    width: "100%", // Default to full width for desktop
    margin: "0 auto",
  };

  const sectionStyle = {
    marginBottom: "3rem", // Increased from 1.5rem to 3rem
  };

  const subHeaderStyle = {
    fontSize: "clamp(1.5rem, 3vw, 2.5rem)",
    fontWeight: 600,
    marginBottom: "1rem",
    color: "#fe7159ff", // Updated color
    textAlign: "left", // Left-aligned for desktop
  };

  const paragraphStyle = {
    fontSize: "clamp(1rem, 2vw, 1.2rem)",
    lineHeight: 1.6,
    textAlign: "justify",
    textJustify: "inter-word",
    wordSpacing: "-1px",
    marginBottom: "1rem",
  };

  const boldStepStyle = {
    fontWeight: 900, // Increased from 800 to 900 for maximum boldness
    fontSize: "1.2em", // Increased from 1.1em to 1.2em
    color: "#ffffff", // Changed to white for maximum contrast
    textShadow: "1px 1px 2px rgba(0,0,0,0.5)", // Added text shadow for emphasis
  };

  return (
    <div
      className="about-page-container"
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "90vh",
        overflowY: "auto",
        color: "#cfcfc4fd",
        fontFamily: "'Poppins', sans-serif",
      }}>
      <div style={contentStyle} className="responsive-content">
        <h1
          style={{
            fontSize: "clamp(2.1rem, 5vw, 3.5rem)",
            fontWeight: 600,
            textAlign: "center",
            marginBottom: "4rem", // Increased from 4rem to 5rem
            color: "#cfcfc4fd", // Reverted to original color
          }}>
          About Whitetail Buck Scoring
        </h1>

        <div style={sectionStyle}>
          <h2 style={subHeaderStyle}>How it works</h2>
          <p style={paragraphStyle}>
            <strong style={boldStepStyle}>Step 1)</strong> Click the Upload
            Photo button to upload a photo of your buck (whitetail deer only)
          </p>
          <p style={paragraphStyle}>
            <strong style={boldStepStyle}>Step 2)</strong> Click Score Buck and
            our computer vision model will estimate your buck's gross antler
            score
          </p>
          <p style={paragraphStyle}>
            Our Deer Scoring Tool uses a computer vision algorithm called a
            convolutional neural network. We use a pre-trained model that has
            learned from millions of images to detect basic patterns and
            recognize different objects within an image. From there, our team
            further trained the model on tens of thousands of whitetail buck
            images so it could learn how to estimate a buck's gross antler
            score.
          </p>
        </div>

        <div style={sectionStyle}>
          <h2 style={subHeaderStyle}>How accurate is it?</h2>
          <p style={paragraphStyle}>
            For most typical bucks where the deer is facing towards the camera,
            our model's can estimate within +/- 5 inches of the actual score.
            For non-typical deer, images where the deer is facing away from the
            camera, or images where part of the rack is not visible, our model
            is less accurate but is usually within 10-15 inches of the actual
            score. The Deer Scoring Tool will never be as accurate as actually
            measuring your deer, but it can provide a rough estimate of a deer's
            gross score if you only have a picture to go by.
          </p>
        </div>

        <div style={sectionStyle}>
          <h2 style={subHeaderStyle}>Who should use this?</h2>
          <p style={paragraphStyle}>
            This tool is ideal for hunters who are inexperienced at estimating a
            deer's antler score or for those looking to improve their field
            judging skills.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
