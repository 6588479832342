import React, { useState, useEffect, useMemo } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./contact-page.css";
import activity_icons_orange from "../images/activity_icons_off_black_four.png";
import axios from "axios"; // Make sure to install axios: npm install axios
import { v4 as uuidv4 } from "uuid"; // Import UUID

const ContactPage = () => {
  const [formData, setFormData] = useState({
    id: "", // Add id to formData
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    // Generate a new UUID when the component mounts
    setFormData((prevData) => ({ ...prevData, id: uuidv4() }));

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const viewportMeta = document.createElement("meta");
    viewportMeta.name = "viewport";
    viewportMeta.content =
      "width=device-width, initial-scale=1, maximum-scale=1";
    document.head.appendChild(viewportMeta);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      document.head.removeChild(viewportMeta);
    };
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const currentDate = new Date();
      const isoString =
        currentDate.getFullYear() +
        "-" +
        ("0" + (currentDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + currentDate.getDate()).slice(-2) +
        "T" +
        ("0" + currentDate.getHours()).slice(-2) +
        ":" +
        ("0" + currentDate.getMinutes()).slice(-2) +
        ":" +
        ("0" + currentDate.getSeconds()).slice(-2) +
        "." +
        ("00" + currentDate.getMilliseconds()).slice(-3) +
        "Z";

      console.log("Submitting form data:", formData);
      const response = await fetch(
        "https://lzmtfrliye.execute-api.us-east-1.amazonaws.com/prod/contact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
            createdAt: isoString,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Form submitted successfully:", data);
      setIsSubmitted(true);
      setFormData({
        id: uuidv4(),
        firstName: "",
        lastName: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      console.error("Error details:", error.message);
      alert("There was an error submitting the form. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const contentStyle = {
    padding: "1rem",
    width: isMobile ? "85%" : "100%",
    margin: "0 auto",
    maxWidth: "600px",
    height: "85%",
    overflowY: "visible",
    textAlign: "left",
  };

  const inputBoxes = useMemo(
    () => ({
      padding: isMobile ? "0.5rem" : "0.8rem",
      fontSize: isMobile ? "16px" : "1rem",
      backgroundColor: "#cfcfc4fd",
      color: "#000000",
      border: "none",
      borderRadius: "4px",
      width: "100%",
      boxSizing: "border-box",
      minHeight: isMobile ? "1.4rem" : "auto",
      lineHeight: "1.5",
      appearance: "none",
      WebkitAppearance: "none",
      MozAppearance: "none",
    }),
    [isMobile]
  );

  const labelStyle = {
    fontSize: isMobile ? "1rem" : "1.2rem",
    fontWeight: "500",
    marginBottom: isMobile ? "0.3rem" : "0.5rem",
    textAlign: "start",
    color: "#cfcfc4fd",
  };

  useEffect(() => {
    if (isMobile) {
      console.log("Mobile input padding:", inputBoxes.padding);
    }
  }, [isMobile, inputBoxes]);

  const buttonStyle = {
    ...inputBoxes,
    marginTop: ".7rem",
    backgroundColor: "#fe7159ff",
    borderColor: "#fe7159ff",
    borderRadius: ".8rem",
    fontSize: "1.2rem",
    color: "#ffffff",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "all 0.3s ease",
    transform: isSubmitting ? "scale(0.95)" : "scale(1)",
    opacity: isSubmitting ? 0.7 : 1,
  };

  const thankYouStyle = {
    marginTop: ".7rem",
    textAlign: "center",
    color: "#cfcfc4fd",
    fontWeight: "bold",
    fontSize: isMobile ? "0.9rem" : "1.1rem",
  };

  return (
    <div
      className="contact-page-container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        overflowY: "auto",
        color: "#cfcfc4fd",
        fontFamily: "'Poppins', sans-serif",
        padding: "1rem",
      }}>
      <div style={contentStyle} className="responsive-content">
        <h1
          style={{
            fontSize: "clamp(1.6rem, 5vw, 3rem)",
            fontWeight: 600,
            textAlign: "center",
            color: "#cfcfc4fd",
          }}>
          Contact OpenWoods
        </h1>
        <div style={{ textAlign: "center", marginBottom: ".5rem" }}>
          <img
            src={activity_icons_orange}
            alt="Activity Icons"
            style={{
              maxWidth: "100%",
              height: "4rem",
              maxHeight: "100px",
            }}
          />
        </div>
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="row mb-1">
            <div className="col-md-6 mb-1 mb-md-0">
              <label
                htmlFor="firstName"
                className="form-label"
                style={labelStyle}>
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                style={inputBoxes}
              />
            </div>
            <div className="col-md-6">
              <label
                htmlFor="lastName"
                className="form-label"
                style={labelStyle}>
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                style={inputBoxes}
              />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="form-label" style={labelStyle}>
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              style={inputBoxes}
            />
          </div>
          <div className="mb-1">
            <label htmlFor="message" className="form-label" style={labelStyle}>
              How can we help?
            </label>
            <textarea
              className="form-control"
              id="message"
              name="message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
              style={inputBoxes}></textarea>
          </div>
          {!isSubmitted ? (
            <button
              type="submit"
              className="btn btn-primary w-100"
              style={buttonStyle}
              disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          ) : (
            <div style={thankYouStyle}>
              Thank you for your message. We will get back to you soon!
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ContactPage;
