import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import arrowhead_img from "../images/arrowhead_v3.png";
import upload_deer_img_placeholder from "../images/upload_deer_img_placeholder.png";
import "./antler-scoring-page.css";
import CountUp from "react-countup";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return isMobile;
};

const AntlerScoringTool = () => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [score, setScore] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useIsMobile();

  const convertToJPEG = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(resolve, "image/jpeg", 0.95);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      let jpegFile = file;
      if (file.type !== "image/jpeg") {
        jpegFile = await convertToJPEG(file);
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage({ file: jpegFile, preview: reader.result });
      };
      reader.readAsDataURL(jpegFile);
    }
  };

  const handleScoreBuck = async () => {
    if (!uploadedImage) {
      alert("Please upload an image first.");
      return;
    }

    console.log("1. Score Buck clicked, setting isLoading to true");
    setIsLoading(true);
    setScore(null);

    try {
      console.log("2. Inside try block, before FileReader");
      const base64data = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          console.log("3. FileReader onloadend");
          resolve(reader.result.split(",")[1]);
        };
        reader.onerror = reject;
        reader.readAsDataURL(uploadedImage.file);
      });

      console.log("4. After FileReader, before API call");
      const result = await fetch(
        "https://gsxe6hxfee.execute-api.us-east-1.amazonaws.com/default/deer-scoring-tool-v2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ body: base64data }),
        }
      );

      console.log("5. After API call, Response status:", result.status);

      const responseText = await result.text();
      console.log("6. Raw response:", responseText);

      if (!result.ok) {
        throw new Error(
          `HTTP error! status: ${result.status}, message: ${responseText}`
        );
      }

      const data = JSON.parse(responseText);
      console.log("7. Parsed response data:", data);

      setScore(data.score);
      console.log("8. After setScore");
    } catch (error) {
      console.error("9. Error scoring buck:", error);
      alert("An error occurred while scoring the buck. Please try again.");
    } finally {
      console.log("10. Before setIsLoading(false)");
      setIsLoading(false);
      console.log("11. After setIsLoading(false)");
    }
  };

  return (
    <div
      className="as-page-container"
      style={{
        display: "flex",
        flexDirection: "column",
        height: isMobile ? "90vh" : "90vh", // This will make it take up 90% of the viewport height
        overflow: "hidden", // This will prevent scrolling within this container
      }}>
      <div
        className="as-input-container"
        style={{
          display: "flex",
          height: isMobile ? "60%" : "75%",
          width: "auto",
          flexDirection: "column",
        }}>
        <div
          className="form-title"
          style={{
            height: "20%",
            flexDirection: "column",
            paddingLeft: "0.6em", // outside element
            paddingRight: "0.6em", // outside element
            fontSize: "clamp(2.1rem, 5vw, 3.5rem)", // Increased font size
            fontWeight: 600, // Semi-bold weight
            fontFamily: "'Poppins', sans-serif", // New font family
            textAlign: "center", // Center the text
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#cfcfc4fd", // Desert tan color
            letterSpacing: "-0.01em", // Slight negative letter spacing for a modern look
          }}>
          Whitetail Buck Scoring
        </div>
        <div
          className="form-subtitle"
          style={{
            flexShrink: 0, // Prevent shrinking
            padding: "0.4em",
            fontSize: "clamp(0.7rem, 1.5vw, 1rem)", // Slightly reduced font size
            fontFamily: "'Poppins', sans-serif", // Added Poppins font
            fontWeight: 400, // Regular weight
            textAlign: "center",
            color: "#cfcfc4fd", // Desert tan color
          }}>
          Estimates a gross B&C score
        </div>
        <div
          className="as-deer-image"
          style={{
            flex: 1, // Take up remaining space
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: isMobile ? "0.35em" : "0.6em", // Smaller margin for mobile
            borderRadius: "30px",
            height: "100%",
            width: "100%",
            overflow: "hidden",
          }}>
          <img
            src={
              uploadedImage
                ? uploadedImage.preview
                : upload_deer_img_placeholder
            }
            alt={uploadedImage ? "Uploaded" : "Default"}
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              width: isMobile ? "80%" : "auto", // Limit width to 80% on mobile
              objectFit: "contain",
              borderRadius: "30px",
              border: "none", // Add this line
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "10%",
          width: "auto",
          justifyContent: "center",
          alignItems: "center",
          padding: ".5rem",
          gap: "1rem", // Add this line to create space between buttons
        }}>
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          id="upload-button"
          onChange={handleImageUpload}
        />
        <label htmlFor="upload-button">
          <Button
            variant="secondary"
            size={isMobile ? "md" : "lg"}
            className="upload-button"
            style={{
              backgroundColor: "#cfcfc4fd",
              borderColor: "#cfcfc4fd",
              color: "black", // Assuming black text for contrast, adjust if needed
              fontFamily: "'Poppins', sans-serif", // Added Poppins font
              fontWeight: 500, // Medium weight
            }}
            onClick={() => document.getElementById("upload-button").click()}>
            Upload Photo
          </Button>
        </label>
        {uploadedImage && (
          <Button
            variant="primary"
            size={isMobile ? "md" : "lg"}
            className="score-button custom-button"
            onClick={handleScoreBuck}
            disabled={isLoading}>
            {isLoading ? "Scoring..." : "Score Buck"}
          </Button>
        )}
      </div>

      <div
        className="output-container"
        style={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "flex-start", // Changed to flex-end to position content at the bottom
          alignItems: "center",
          height: "100%", // Take up all available height
          padding: "1rem", // Add some padding at the bottom
        }}>
        <div className="output-box">
          <div style={{ position: "relative" }}>
            <img
              className="arrowhead-img"
              src={arrowhead_img}
              alt="arrowhead img"
              style={{
                width: isMobile ? "107%" : "65%",
                height: "auto",
                visibility: isLoading || score === null ? "hidden" : "visible",
                maxWidth: "300px",
              }}
            />
            <div
              className="score-display"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "white",
                textAlign: "center",
                fontSize: isMobile ? "1.6rem" : "2rem", // Increased font size for both mobile and desktop
                fontWeight: "bold", // Make the text bold for better visibility
                fontFamily: "'Poppins', sans-serif", // New font family
              }}>
              {isLoading ? (
                <div className="loading-dots">
                  <span>.</span>
                  <span>.</span>
                  <span>.</span>
                </div>
              ) : score !== null ? (
                <CountUp start={0} end={score} duration={0.33} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AntlerScoringTool;
