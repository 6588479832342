import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap"; // Import the Container component
import "./App.css"; // Import your custom CSS file for styling
import HomePage from "./pages/HomePage";
import AntlerScoringTool from "./pages/AntlerScoringPage"; // Ensure this path is correct
import AboutPage from "./pages/AboutPage"; // Import the AboutPage component
import ContactPage from "./pages/ContactPage"; // Import the ContactPage component

function App() {
  const [expanded, setExpanded] = useState(false);
  const navbarRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setExpanded(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <BrowserRouter>
      <div
        className="App"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          overflow: "hidden", // Add this line
        }}>
        <Navbar
          ref={navbarRef}
          className="navbar"
          expand="lg"
          style={{ flexShrink: 0 }}
          expanded={expanded}
          onToggle={(expanded) => setExpanded(expanded)}>
          <Container fluid>
            <Navbar.Brand as={Link} to="/" className="logo-container">
              <img
                src={process.env.PUBLIC_URL + "/images/ow_logo_new-cropped.svg"}
                className="logo"
                alt="Your Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link
                  as={Link}
                  to="/"
                  className="nav-link"
                  onClick={() => setExpanded(false)}>
                  Buck Scoring
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/about"
                  className="nav-link"
                  onClick={() => setExpanded(false)}>
                  About
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/contact"
                  className="nav-link"
                  onClick={() => setExpanded(false)}>
                  Contact
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}>
          <Routes>
            <Route path="/" element={<AntlerScoringTool />} />
            <Route path="/about" element={<AboutPage />} />{" "}
            {/* Add this line */}
            {/* You can add other routes here if needed */}
            <Route path="/contact" element={<ContactPage />} />{" "}
            {/* Add this line */}
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
